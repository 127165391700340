import React from "react";

export const LINKEDIN = "https://www.linkedin.com/search/results/all/?keywords=awad%20shahadat&origin=GLOBAL_SEARCH_HEADER&sid=ST)"
export const GITHUB = "https://github.com/awadbs"
// export const TWITTER = "https://twitter.com/Awads_"
// export const LOCATION = "WASHINGTON, D.C."
export const MEDIUM = "https://medium.com/@headbigboy"
// export const VSCO = "https://vsco.co/tesla-motors/gallery"
export const EMAIL = `mailto:${"awadspam@gmail.com"}?subject=${encodeURIComponent(" ") || ''}&body=${encodeURIComponent(" ") || ''}`
export const ABOUTME = "Software engineer for CRMC, 4 year tenure. \n \nPreviously at Data Machines Corp and Costar Group.\nInterests: computer vision, blockchain, and fullstack development. \nWinner of HackBI and RamHacks, and participant of several hackathons. \nMay 2022 computer science graduate from George Mason University. \n\nI like doing things different, learning, and helping others."
export const FOOTERTEXT = '   2006 \n Made with hate © \n Springfield, VA \n '
