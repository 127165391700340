import React from 'react';
import { IconType } from "react-icons";
import { cn } from "../../hooks-utils"

interface IconButtonProps {
    icon: IconType;
    onClick?: () => void;
    additionalClasses?: string;
  }

export const IconButton: React.FC<IconButtonProps> = ({ icon: Icon, onClick, additionalClasses }) => {
    return (
    <button className={cn("button-50 rounded-lg md:rounded-xl text-l md:text-[1.5rem]", additionalClasses)} onClick={onClick}>
        <Icon />
    </button>
  );
};

