import React, { useEffect, useMemo, useState } from "react";
// import {ReactComponent as Signature} from './SignatureLogo.svg';
import {ReactComponent as Sig} from "./A_Logo_signature.svg";
import { Header } from "../../Components/header/Header";
import { ImageGallery } from "../../Components/image-gallery/ImageGallery"
import { useTrueAtMediaBreakpointLessThen } from "../../hooks-utils";
import { NavigationButton } from "../../Components/navigation-button";
export const Home: React.FC = () => {
    // const [isAnimated, setIsAnimated] = useState(false);
    // useEffect(() => {
    //     // Set the header to animate after 1 second
    //     const timer = setTimeout(() => {
    //         setIsAnimated(true);
    //     }, 1000);

    //     return () => clearTimeout(timer);
    // }, []);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {setWindowWidth(window.innerWidth)};
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);
      console.log("###", windowWidth);
      const getWidth = () => {
        if (windowWidth < 768) return '';
        if (windowWidth < 925) return 'md:w-6/12';
        if (windowWidth < 1200) return 'md:w-7/12';
        return 'md:w-7/12';
      };
    return (
      <>
      <div className={`container p-5 mx-auto max-w-7xl`}>
            <Header/>
            <div className={`md:ml-auto md:pt-28 w-full ${getWidth()}`}> 
              <ImageGallery/>
            </div>
            {/* // <ImageGallery /> */}
            {/* ${boxHeight === 'h-[150px]' ? 'h-19' : 'h-[calc(1.25*96vh)]'}  */}
        </div>
        <img className="fixed top-0 left-0 w-full h-full object-cover mix-blend-screen pointer-events-none z-2" src="/texture.jpg" alt="paper texture"></img>

      </>
    );
}
