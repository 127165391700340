import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Home } from "./Pages/Home";
// Your other imports remain the same ...

// Sample page components (you can move these to separate files)

const Publications = () => <div>Publications Content</div>;
const ArtGallery = () => <div>Art Gallery Content</div>;
const Travel = () => <div>Travel Content</div>;

function App() {
  return (
    // <Router>
    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       {/* <Route path="/publications" element={<Publications/>} />
    //       <Route path="/art-gallery" element={<ArtGallery/>} />
    //       <Route path="/travel" element={<Travel />} /> */}
    //       <Route path="*" element={<Navigate to="/" replace />} />
    //     </Routes>
    // </Router>
      <Home />
  );
}

export default App;
