import React from "react";
import { CiMail, CiLinkedin } from "react-icons/ci";
import { PiGithubLogoLight } from "react-icons/pi";
import { PiMediumLogoLight } from "react-icons/pi";

import { IconButton } from "./IconButton"
import { LINKEDIN, GITHUB, EMAIL, MEDIUM } from "./Constants";

export function SocialButtonRow() {
    
    const handleEmailClick = () => {
        window.location.href = `${EMAIL}`;
    };

    // GitHub click handler
    const handleGitHubClick = () => {
        window.open(GITHUB, '_blank');
    };

    // LinkedIn click handler
    const handleLinkedInClick = () => {
        window.open(LINKEDIN, '_blank');
    };
     // LinkedIn click handler
     const handleMediumClick = () => {
        window.open(MEDIUM, '_blank');
    };

    return (
        <div className="left-3 relative w-auto md:block md:absolute md:left-[25%]">

        <div className="flex space-x-4">
            <IconButton onClick={handleEmailClick} icon={CiMail}/>
            <IconButton onClick={handleGitHubClick} icon={PiGithubLogoLight}/>
            <IconButton onClick={handleLinkedInClick} icon={CiLinkedin}/>
            <IconButton onClick={handleMediumClick} icon={PiMediumLogoLight}/>

        </div>
        </div>

    );
}