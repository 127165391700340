import React, { useState, useEffect } from 'react';
import IMAGE_DATA from './images.json';

export const ImageGallery: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    IMAGE_DATA.forEach((_, index) => {
      setLoadingStatus(prev => ({ ...prev, [index]: true }));
    });
  }, []);


  const handleImageClick = (index: number) => {
    setSelectedImage(selectedImage === index ? null : index);
  };

  const handleImageLoad = (index: number) => {
    setLoadingStatus(prev => ({ ...prev, [index]: false }));
  };
  
  return (
    <>
      <div className={`grid grid-cols-2 gap-4`}>
      {IMAGE_DATA.map((image, index) =>(
          <div key={index} className={`${selectedImage === index ? 'row-span-2 col-span-full' : ''} transition-all duration-500`}>
              {loadingStatus[index] && <div className="image-skeleton w-full h-52 bg-gray-200 animate-pulse rounded"></div>}
            <img
              src={image.url}
              loading="lazy"
              className={`w-full h-auto object-cover rounded shadow-images ${loadingStatus[index] ? 'hidden' : ''}`}
              alt={`img ${index + 1}`}
              onClick={() => handleImageClick(index)}
              onLoad={() => handleImageLoad(index)}
            />
            <span className="text-white text-xs font-serif mt-1">
              {image.caption}
            </span>
          </div>
        ))}
    </div>
    <div className='flex justify-center mt-4'>
        <span className='text-white text-sm font-serif'>
          {"[ nikon d7500, iphone 13, iphone 11, iphone x]"}
        </span>
      </div>
    </>

  );
};
