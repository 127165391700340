import React, { useEffect, useState } from "react";
import {ReactComponent as Signature} from './SignatureLogo.svg';
import { useBrowserType, BrowserTypeMap, useTrueAtMediaBreakpointLessThen, cn } from "../../hooks-utils";
import { SignatureLogoSvgComponent } from "./SignatureLogo"
import  { SocialButtonRow } from "../social-buttons/SocialButtonRow"
import { NavigationButton } from "../navigation-button";
export const initialContainerHeightStyles: BrowserTypeMap = {
    'Mobile Safari': 'h-[calc(100vh-120px)]',
    'Mobile Chrome': 'h-[calc(100vh-150px)]',
    'Desktop': 'h-[calc(100vh-40px)]',
    'Others': 'h-[calc(100vh-40px)]',
  };

export const Header = () => {
    const browser = useBrowserType();
    const browserHeightClassName = initialContainerHeightStyles[browser];
    // const isMobile = useTrueAtMediaBreakpointLessThen();

    return (
        <header className="items-center static block mx-auto mb-6 w-5/6 md:flex md:h-full md:fixed transition-all duration-1000">
            <div className="items-center right-3 flex relative h-auto md:block">

                <SignatureLogoSvgComponent color="#357266" className={`w-8/12 max-w-sm h-1/6 md:h-full md:w-full transition-all duration-1000`} />  
                <SocialButtonRow/>
                {/* <div className="left-3 relative w-auto md:block md:absolute md:left-[25%]">
                    <SocialButtonRow/>
                    <div className="md:hidden relative top-5">
                        <NavigationButton/>
                    </div>
                </div> */}
                {/* <div className="bg-black static w-auto">
                    <SocialButtons/>
                </div> */}
                {/* ${boxHeight === 'h-[150px]' ? 'h-32 object-left-top opacity-100' : 'h-72 object-cover opacity-90'} */}
            </div>
        </header>
    )

}

//export const Header = (props: HeaderProps) => {
/**
 *  <header className={`relative flex justify-center items-center transition-height duration-150 rounded-lg z-10 shadow-lg bg-[#Ffe0b5] opacity-90 w-[calc(100vw - 4vh)] ${browserHeightClassName}`}>
            <Signature className={`w-9/12 transition-opacity duration-200`} />  
            </header>
 */